import React from 'react'

import { CheckoutLayout, CheckoutStatus } from '../../components'

const CheckoutStatusTemplate = ({ pageContext }) => {
  const { lang, page } = pageContext

  return (
    <CheckoutLayout lang={lang} switcher={page} allowClosedCart={true}>
      <CheckoutStatus lang={lang} />
    </CheckoutLayout>
  )
}

export default CheckoutStatusTemplate
